import GATSBY_COMPILED_MDX from "/opt/build/repo/data/sample3.mdx";
import * as React from "react";
import * as classes from "./blogPost.module.scss";
var query = "3664316855";
var BlogPost = function BlogPost(_ref) {
  var _data$mdx, _data$mdx$frontmatter, _data$mdx2, _data$mdx2$frontmatte;
  var data = _ref.data, children = _ref.children;
  return React.createElement("article", {
    className: classes["container"]
  }, React.createElement("div", null, (_data$mdx = data.mdx) === null || _data$mdx === void 0 ? void 0 : (_data$mdx$frontmatter = _data$mdx.frontmatter) === null || _data$mdx$frontmatter === void 0 ? void 0 : _data$mdx$frontmatter.title, " "), React.createElement("div", null, (_data$mdx2 = data.mdx) === null || _data$mdx2 === void 0 ? void 0 : (_data$mdx2$frontmatte = _data$mdx2.frontmatter) === null || _data$mdx2$frontmatte === void 0 ? void 0 : _data$mdx2$frontmatte.datePublished), children);
};
export var Head = function Head(_ref2) {
  var _data$mdx3, _data$mdx3$frontmatte;
  var data = _ref2.data;
  return React.createElement(React.Fragment, null, React.createElement("title", null, (_data$mdx3 = data.mdx) === null || _data$mdx3 === void 0 ? void 0 : (_data$mdx3$frontmatte = _data$mdx3.frontmatter) === null || _data$mdx3$frontmatte === void 0 ? void 0 : _data$mdx3$frontmatte.title));
};
BlogPost
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPost, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
